import type { Tasks } from 'lvl-io-sdk'
import { createSimpleStore } from '~/utils/pinia/light-simple-store'
import { useTasksListStore } from './list'

const useTaskPrioritiesPrivateStore = defineStore(
  'task-priorities-private',
  () => {
    const isEnabled = ref(true)

    return { isEnabled }
  }
)

export const useTaskPrioritiesStore = createSimpleStore(
  'task-priorities',
  {
    getRequests: async () => {
      const { $sdk } = useNuxtApp()
      const { getPriorities } = await $sdk.module('tasks')

      return {
        get: (
          ...[requestQuery, pathOptions, requestOptions]: Parameters<
            typeof getPriorities
          >
        ) =>
          getPriorities(
            {
              limit: 5,
              ...(requestQuery ?? {})
            },
            pathOptions,
            requestOptions
          )
      }
    }
  },
  context => {
    const { $sdk } = useNuxtApp()

    const privateStore = useTaskPrioritiesPrivateStore()
    const tasksListStore = useTasksListStore()

    const isEnabled = computed(() => privateStore.isEnabled)

    const toggleEnabled = async () => {
      const newValue = !isEnabled.value

      privateStore.isEnabled = newValue

      if (!context.fetching.value && newValue) {
        await context.fetchData()
      }
    }

    const upTask = async (id: Tasks.Task['id']) => {
      const result = await $sdk.module('tasks').then(({ upTask }) => upTask(id))

      await Promise.all([context.fetchData(), tasksListStore.fetchItems()])

      return result
    }

    const getTaskPriority = (id: Tasks.Task['id']) =>
      (context.data.value?.indexOf(id) ?? -1) + 1

    return {
      isEnabled,
      toggleEnabled,
      upTask,
      getTaskPriority
    }
  }
)
