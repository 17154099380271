import { skipHydrate } from 'pinia'
import { useSessionStorage } from '@vueuse/core'
import type { Tasks } from 'lvl-io-sdk'
import { createItemsStore } from '~/utils/pinia/light-items-store'

type ExternalReward = Tasks.Task['rewards'][number]

type Resource = Pick<
  ExternalReward['resource'],
  'id' | 'name' | 'image_url' | 'source' | 'source_id'
>

type Reward = Pick<ExternalReward, 'amount'> & {
  resource: Resource
}

type Task = Pick<
  Tasks.Task,
  'id' | 'content' | 'options' | 'stat' | 'memberStatus'
> & {
  rewards: Reward[]
}

const FIELDS = [
  'id',
  'content',
  'options',
  'stat',
  'memberStatus',
  'rewards.amount',
  'rewards.resource.id',
  'rewards.resource.name',
  'rewards.resource.image_url',
  'rewards.resource.source',
  'rewards.resource.source.id',
  'rewards.resource.source_id'
]

export const useTasksListStore = createItemsStore(
  'tasks-list',
  {
    getItemsRequests: async () => {
      const { $sdk } = useNuxtApp()
      const {
        tasks: { idKey, getItems, getMoreItems }
      } = await $sdk.module('tasks')

      return {
        idKey,
        getItems: getItems<Task>,
        getMoreItems: getMoreItems<Task>
      }
    }
  },
  context => {
    const defaults = reactive({
      status: {
        available: {
          'filter[unlocked_at]': 'NULL'
        },
        inprogress: {
          'filter[not][unlocked_at]': 'NULL',
          'filter[completed_at]': 'NULL'
        },
        completed: {
          'filter[progress]': 100
        }
      }
    })

    const page = skipHydrate(useSessionStorage('dphub/tasks/list/page', 1))

    const reset = () => {
      page.value = 1
      context.reset()
    }

    const fetchItems = (
      ...[requestQuery, pathOptions, requestOptions]: Parameters<
        (typeof context)['fetchItems']
      >
    ) => {
      const route = useRoute()

      const filterStatusPreset = route.query.status
        ? defaults.status[route.query.status as keyof typeof defaults.status]
        : null

      const query = {
        page: page.value,
        perPage: 14,
        'filter[tag]': route.query.type || null,
        ...(filterStatusPreset ?? {}),
        fields: FIELDS,
        ...(requestQuery ?? {})
      }

      return context.fetchItems(query, pathOptions, {
        resetBeforeFetch: false,
        ...(requestOptions ?? {})
      })
    }

    return { defaults, page, reset, fetchItems }
  }
)
