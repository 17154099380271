<template>
  <button
    v-if="isVisible"
    :class="classes"
    type="button"
    :disabled="isFirst || isPending"
    @click.stop="upTask.execute()"
  >
    <span :class="$style.content">
      <span v-if="isValueVisible" :class="$style.t">{{ priority }}</span>

      <ui-icon :class="$style.icon" name="up" />
    </span>

    <span :class="$style.overlay">
      <ui-spinner
        v-if="isPending"
        :class="$style.spinner"
        theme="current"
        :size="1.6"
      />

      <span :class="$style.t">{{ t('text') }}</span>

      <ui-icon :class="$style.icon" name="up" />
    </span>
  </button>
</template>

<i18n>
{
  "ru": {
    "text": "Сделать главной"
  }
}
</i18n>

<script lang="ts" setup>
import type { Tasks } from 'lvl-io-sdk'
import UiIcon from '~/components/ui/icon/index.vue'
import UiSpinner from '~/components/ui/spinner/index.vue'
import { useTaskPrioritiesStore } from '~/stores/tasks/priorities'
import { getTaskStatus } from '~/components/task/helpers/helpers'

const props = defineProps<{
  taskId: Tasks.Task['id']
  memberStatus?: Tasks.MemberStatus | null
}>()

const { t } = useI18n()
const id = useId()
const cssModule = useCssModule()

const taskPrioritiesStore = useTaskPrioritiesStore()
const { fetched: arePrioritiesFetched, isEnabled: arePrioritiesEnabled } =
  storeToRefs(taskPrioritiesStore)

const taskStatus = computed(() => getTaskStatus(props.memberStatus))

const isVisible = computed(
  () =>
    arePrioritiesEnabled.value &&
    arePrioritiesFetched.value &&
    // TODO: use can.up instead
    props.memberStatus?.is_priority &&
    taskStatus.value === 'progress'
)

const priority = computed(() =>
  taskPrioritiesStore.getTaskPriority(props.taskId)
)

const isFirst = computed(() => priority.value === 1)
const isValueVisible = computed(() => priority.value && !isFirst.value)

const classes = computed(() => [
  cssModule.button,
  {
    [cssModule.first]: isFirst.value,
    [cssModule.slim]: !isValueVisible.value
  }
])

const upTask = useAsyncData(
  id,
  () => taskPrioritiesStore.upTask(props.taskId),
  {
    server: false,
    immediate: false
  }
)

const { isPending } = useAsyncDataStatus(upTask)
</script>

<style lang="scss" module>
.button {
  border-radius: 0.4em;
  min-width: 4.5em;
  height: 3.2em;
  color: #00bbff;
  background: var(--t-form-bg);
  position: relative;

  @include hover-focus {
    .overlay {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.slim {
    min-width: 3.6em;
  }

  &.first {
    color: #131616;
    background: #00bbff;
    pointer-events: none;
  }
}

.content {
  padding: 0 0.8em;
  display: flex;
  gap: 0.2em;
  justify-content: center;
  align-items: center;
}

.t {
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.4545;
  letter-spacing: -0.025em;
}

.icon {
  width: 2em;
  height: 2em;
}

.overlay {
  composes: content;

  opacity: 0;
  transform: scale(0, 1);
  transform-origin: right;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  white-space: nowrap;
  color: #131616;
  background: #00bbff;
  will-change: opacity, transform;
  transition-property: opacity, transform;
  transition-duration: 0.4s;
}

.spinner {
  margin-right: 0.4em;
}
</style>
